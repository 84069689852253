.logo {
    position: relative;
    width: 300px;
    margin: auto;
}

.text--center {
    text-align: center;
}

.badge {
    position: absolute;
    right: 0;
    bottom: -25px;
    width: auto;
    height: 100px;
}

.container {
    color: #fefefe;
    margin-top: 3rem;
}

body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

main {
    flex: 1;
}

body {
    background: -ms-linear-gradient(top, #87B09A, #a1c9c7);
    background: linear-gradient(to bottom, #87B09A, #a1c9c7);
}

footer {
    text-indent: -999px;
    padding-top: 5%;
    background: url(/assets/img/background-hills.svg) no-repeat center bottom;
}

.img-circle {
    border-radius: 50%;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.top-buffer {
    margin-top: 3rem;
}

ul li {
    /*
     * We want the bullets outside of the list,
     * so the text is aligned. Now the actual bullet
     * is outside of the list’s container
     */
    list-style-position: outside;

    /*
     * Because the bullet is outside of the list’s
     * container, indent the list entirely
     */
    margin-left: 1em;
}

h1 small {
    font-size: 32px;
}
